﻿<template>
    <aunoa-form-pane
        v-if="showQueryForm"
        :entity="query"
        :entity-model="queryModel"
        :form-model="queryFormModel"
        :lookup-factories="lookupFactories"
    >
        <template v-slot:navItems>
            <aunoa-nav-item ref="submitButton" type="submit" :text="t(startLoc)" @click="onQuery" />
            <aunoa-nav-item :text="t('Aunoa.Command.Query.Query')" v-if="query">
                <template v-if="queryFormModel || options.saveQuery">
                    <aunoa-dropdown-item icon="save" :text="t('Aunoa.Command.SaveAs')+'&hellip;'" disabled v-if="testMode" />
                    <aunoa-dropdown-divider v-if="testMode" />
                </template>
                <template v-if="false">
                    <aunoa-dropdown-item text="German locations (tenant:TS-DE)" icon="far" />
                    <aunoa-dropdown-item text="British locations (tenant:TS-GB)" icon="far" />
                    <aunoa-dropdown-divider />
                </template>
                <aunoa-dropdown-item icon="fad fa-share fa-flip-horizontal" :text="t('Aunoa.Command.Reset')" @click="onResetQuery" />
            </aunoa-nav-item>
            <aunoa-nav-item
                v-if="createPermission && createSupported && formModel && !options.addDisabled"
                type="submit"
                class="font-weight-normal"
                icon="add"
                :title="t('Aunoa.Command.Add')"
                @click="onCreate"
            />
            <aunoa-nav-item v-if="entityTitle && options.import && true" :text="entityTitle">
                <aunoa-dropdown-item
                    v-if="options.import"
                    icon="fad fa-file-import"
                    :text="t('Aunoa.Command.Import')"
                    @click="onImport"
                />
            </aunoa-nav-item>
            <slot name="navItems" :testMode="testMode" :entities="entities" />
            <aunoa-nav-item
                v-if="createPermission && createSupported && formModel && (testMode || !options.addDisabled && !options.pasteFromClipboardDisabled)"
                icon="more"
                :title="t('Aunoa.Command.More')"
            >
                <aunoa-dropdown-item
                    v-if="testMode"
                    icon="fad fa-external-link-alt"
                    :text="t('Aunoa.Command.AddInNewTab')"
                    disabled
                />
                <aunoa-dropdown-item
                    v-if="!options.addDisabled && !options.pasteFromClipboardDisabled"
                    icon="paste"
                    :text="t('Aunoa.Command.Clipboard.Paste')"
                    end-text=".json"
                    @click="onPaste" />
            </aunoa-nav-item>
        </template>
    </aunoa-form-pane>

    <pre class="small p-3" v-text="JSON.stringify(query, null, 3)" v-if="0" />

    <aunoa-form v-if="showImport">

        <div class="row bg-body pt-5">
            <component
                :is="options.import.component"
                :queryContext="queryContext"
            />
        </div>
        <div class="row border-bottom shadow-sm bg-body">
            <aunoa-nav class="small dashed-top">
                <li class="nav-item">
                    <button class="nav-link" type="submit">
                        <span>Apply</span>
                    </button>
                </li>
                <aunoa-nav-item text="Cancel" @click="onCancelImport" />
            </aunoa-nav>
        </div>

    </aunoa-form>

    <div class="row pt-5" v-if="showResult && gridModel">
        <table class="table table-hack table-striped table-data-grid table-simplified-hover mb-0 bg-body">
            <aunoa-data-head
                v-if="started || newEntities.length>10"
                :grid-model="gridModel"
                :calc-head-height="true"
                :can-close="!options.autoQuery"
                column-class="sticky-top bg-header"
                @close="onResetQuery">
                <template v-slot:before-cells="{columnClass}">
                    <th :class="columnClass">&nbsp;</th>
                </template>
            </aunoa-data-head>
            <aunoa-data-head
                v-else-if="newEntities.length"
                :grid-model="gridModel"
                :calc-head-height="true"
                column-class="sticky-top bg-header">
                <template v-slot:before-cells="{columnClass}">
                    <th :class="columnClass">&nbsp;</th>
                </template>
                <template v-slot="{columnClass, columns}">
                    <th :class="columnClass" :colspan="columns.length" v-text="t('Aunoa.Caption.NewEntity')" />
                </template>
            </aunoa-data-head>

            <tbody v-if="newEntities.length">
            <template v-for="(entity, i) in newEntities" :key="entity.$$.created">
                <tr class="tr-extended">
                    <th :rowspan="3" class="text-center small h-100" />
                    <td :colspan="colspan">
                        <i class="fad fa-lg fa-thumbtack pe-2 fa-rotate-by" style="--fa-rotate-angle: -15deg;" />
                        <span class="fw-bold text-muted" v-text="t('Aunoa.Entity.NewEntity', [i+1, newEntities.length])" />
                    </td>

                </tr>
                <tr class="tr-stripe-equalization">
                    <td>&nbsp;</td>
                </tr>
                <tr class="tr-extension">
                    <td class="p-0" :colspan="colspan">
                        <aunoa-data-grid-inline
                            :entity="entity"
                            :current-query="currentQuery()"
                            mode="edit"
                            :close-on-editor-close="true"
                            :keep-open-on-update="true"
                            :scroll-editor-to-top="true"
                            :crud-service="crudService"
                            :options="options"
                            :entity-model="entity.$$.entityModel"
                            :form-model="entity.$$.formModel"
                            :lookup-factories="lookupFactories"
                            :colspan="colspan"
                            @update="onUpdateNew"
                            @close="onCloseNew">
                            <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
                                <slot :name="slotName" v-bind="slotProps" />
                            </template>
                        </aunoa-data-grid-inline>
                    </td>
                </tr>
            </template>
            </tbody>

            <tbody v-if="hasEntities">
            <aunoa-data-row
                v-for="(entity, index) in entities"
                :key="'row-'+getKey(entity)"
                :grid-model="gridModel"
                :query-context="queryContext"
                :entity="entity"
                :index="index"
                :last="index===entities.length-1"
                :get-key="getKey"
                :is-disabled="isDisabled"
                @click="onRowClick"
                @dblClick="onRowDblClick"
            >
                <template v-slot:inline="{entityContext}">
                    <aunoa-data-grid-inline
                        :entity="entity"
                        :current-query="currentQuery()"
                        :query-context="queryContext"
                        :crud-service="crudService"
                        :options="options"
                        :details-model="detailsModel"
                        :entity-model="entityModel"
                        :form-model="formModel"
                        :lookup-factories="lookupFactories"
                        :colspan="colspan"
                        :create-permission="createPermission"
                        :update-permission="updatePermission"
                        :delete-permission="deletePermission"
                        :close-on-editor-close="true"
                        :can-share="canShare"
                        :can-copy-to-clipboard="canCopyToClipboard"
                        @remove="onRemove(index)"
                        @update="onUpdate($event, entityContext, index)"
                        @close="onClose($event, entityContext)"
                        @duplicate="onDuplicate($event)"
                        ref="dataGridInline">
                        <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
                            <slot :name="slotName" v-bind="slotProps" />
                        </template>
                    </aunoa-data-grid-inline>
                </template>

            </aunoa-data-row>
            </tbody>

            <tbody v-else-if="hasNoEntities && query && !error && !options.autoQuery">
            <tr>
                <th class="text-center">
                    <i class="fal fa-exclamation-triangle fa-2x text-warning" />
                </th>
                <td :colspan="colspan">
                    <div class="fw-bold" style="max-width: 50ch" v-text="t('Aunoa.Entity.NoEntitiesLong')" />
                </td>
            </tr>
            </tbody>
            <tbody v-else-if="hasNoEntities && !error">
            <tr>
                <th class="text-center">
                    <i class="fal fa-circle-info fa-2x text-info" />
                </th>
                <td :colspan="colspan">
                    <div class="fw-bold" style="max-width: 50ch" v-text="t('Aunoa.Entity.NoEntitiesFound')" />
                </td>
            </tr>
            </tbody>



        </table>

        <aunoa-nav class="px-0" v-if="started && entities.length && !hasMore">
            <aunoa-nav-item
                drop-direction="dropup"
                icon="fad fa-check-circle ps-2"
                :text="t('Aunoa.Entity.AllEntitiesLoaded')+'&hellip;'">
                <template v-if="query">
                    <aunoa-dropdown-item
                        icon="fad fa-share fa-flip-horizontal"
                        :text="t('Aunoa.Command.Query.Reset')"
                        @click="reset" />
                    <aunoa-dropdown-divider />
                </template>
                <aunoa-dropdown-item
                    icon="fad fa-arrow-alt-square-up"
                    :text="t('Aunoa.Command.ScrollToTop')"
                    @click="scrollToTop"
                />
            </aunoa-nav-item>
        </aunoa-nav>

    </div>
    <aunoa-footer-commands-pane>
        <slot name="footerCommandsPane">
            <slot name="optionalFooterCommandsPane" />
            <li class="nav-item" v-if="started">
                <span class="nav-text" v-text="t('Aunoa.Entity.NoEntitiesShort')" v-if="count===0" />
                <span class="nav-text" v-text="t('Aunoa.Entity.MoreThanEntitiesCount', [count])" v-else-if="hasMore" />
                <span class="nav-text" v-text="t('Aunoa.Entity.EntitiesCount', [count])" v-else />
            </li>
        </slot>
    </aunoa-footer-commands-pane>

    <div ref="infiniteElement" class="py-5" />


</template>


<script lang="ts">

import type {DataService, Entities, Forms, Lookup, LookupFactories, PromisableEvent, Tables} from "bootstrap-aunoa";

import {
    AunoaDropdownDivider,
    AunoaDropdownItem,
    AunoaNav,
    AunoaNavItem,
    AunoaForm,
    AunoaFormGroup,
    AunoaFormPane,
    AunoaFooterCommandsPane,
    createReactiveEntity,
    useLocale,
    createEntity,
    useToasts,
    useClipboardData,
    useTestMode,
    useEventListener,
    useBodyBackground,
    isUndefinedOrNull
} from "bootstrap-aunoa";

import {computed, defineComponent, PropType, ref, toRefs, unref, watch, onMounted, reactive, toRaw, shallowRef} from "vue";
import {useInfinitePaging} from "../implementations/useInfinitePaging";
import {useDataGridI18n} from "../implementations/useDataGridI18n";
import {useDataService} from "../implementations/useDataService";
import AunoaDataGridInline from "./AunoaDataGridInline.vue";
import AunoaDataHead from "../components/AunoaDataHead.vue";
import AunoaDataRow from "../components/AunoaDataRow";
import {DataGrid} from "../DataGridOptions";
import {useRouter} from "vue-router";


type Mode = "Query" | "Import";

const START_COMMAND = "Aunoa.Command.Start";
const REFRESH_COMMAND = "Aunoa.Command.Refresh";

export default defineComponent({
    name: "AunoaDataGrid",
    components: {
        AunoaNav,
        AunoaNavItem,
        AunoaDropdownItem,
        AunoaDropdownDivider,
        AunoaFooterCommandsPane,
        AunoaDataGridInline,
        AunoaFormGroup,
        AunoaFormPane,
        AunoaDataHead,
        AunoaDataRow,
        AunoaForm
    },
    props: {

        query: {
            type: Object,
            default: undefined
        },
        queryModel: {
            type: Array as PropType<Entities.Model.Property[]>,
            default: undefined
        },
        queryFormModel: {
            type: Object as PropType<Forms.Model.Form>,
            default: undefined
        },

        queryState: {
            default: undefined
        },
        gridModel: {
            type: Object as PropType<Tables.Model.Grid>,
            default: undefined
        },
        detailsModel: {
            type: Object as PropType<Tables.Model.Details>,
            default: undefined
        },

        entityModel: {
            type: Array as PropType<Entities.Model.Property[]>,
            default: undefined
        },
        formModel: {
            type: Object as PropType<Forms.Model.Form>,
            default: undefined
        },

        lookupFactories: {
            type: Object as PropType<LookupFactories>,
            default: undefined
        },
        crudService: {
            type: Object as PropType<DataService>,
            default: undefined
        },
        options: {
            type: Object as PropType<DataGrid.Options>,
            default: undefined
        },

        createPermission: {
            type: Boolean,
            default: true
        },
        updatePermission: {
            type: Boolean,
            default: true
        },
        deletePermission: {
            type: Boolean,
            default: true
        },

        canShare: {
            type: Boolean,
            default: true
        },
        canCopyToClipboard: {
            type: Boolean,
            default: true
        },

        newGroupName: {
            type: String,
            default: ""
        },
        entityTitle: {
            type: String
        }

    },
    emits: ["update", "close", "update:query"],
    setup: (props, {emit}) => {

        const
            {
                lookupFactories,
                queryModel,
                queryFormModel,
                query: _query,
                queryState,
                crudService,
                entityModel: _propsEntityModel,
                formModel: _propsFormModel,
                gridModel,
                newGroupName
            } = toRefs(props);

        const options = computed<DataGrid.Options>(() => props.options || {});
        const pageSize = computed<number>(() => options.value.pageSize || 50);
        const colspan = computed(() => unref(gridModel).columns.length);
        const mode = ref<Mode>("Query");

        const showQueryForm = computed(() => mode.value === "Query");
        const showImport = computed(() => mode.value === "Import");
        const showResult = computed(() => mode.value !== "Import");

        const dataGridInline = shallowRef();

        const {t} = useDataGridI18n();
        const router = useRouter();
        const {testMode} = useTestMode();
        const newEntities = ref<any[]>([]);
        const submitButton = ref();

        const {addWarningToast, addErrorToast} = useToasts();

        const _activeEntityContext = ref<Entities.EntityContext>();
        watch(_activeEntityContext, (newContext, oldContext) => {
            //console.log(newContext?.Entity.Oid ?? "EMPTY");
            if (oldContext?.EntitySelected) {
                oldContext.EntitySelected = false;
            }
            if (newContext && !newContext.EntitySelected) {
                newContext.EntitySelected = true;
            }
        });

        const lookups = computed(() => {
            const o: Record<string, Lookup> = {};
            const factories = unref(lookupFactories);
            if (factories) {
                Object.keys(factories).forEach(name => o[name] = factories[name].create());
            }
            return o;
        })

        watch(newGroupName, () => {
            newEntities.value = [];
        });

        const service = useDataService(crudService)
        const paging = useInfinitePaging(crudService, pageSize);
        const {infiniteElement, endVisible, error} = paging;

        watch(error, e => {
            if (e) {
                addErrorToast({
                    content: e,
                    group: "error"
                });
            }
        });

        const bodyBackground = useBodyBackground();
        watch(paging.promise, promise => {
            promise && bodyBackground.startBodyBusy(promise);
        }, {immediate: true})

        const query = ref({});
        const _entityModel = ref<Entities.Model.Property[]>();
        const _formModel = ref<Forms.Model.Form>();
        const setQuery = (q: any) => {
            query.value = q ? createReactiveEntity(q, queryModel.value) : undefined;
            _entityModel.value = _propsEntityModel.value;
            _formModel.value = _propsFormModel.value;
            emit("update:query", query.value)
        };

        const entityModel = computed(() => _propsEntityModel.value || _entityModel.value);
        const formModel = computed(() => _propsFormModel.value || _formModel.value);

        watch([crudService, _query], ([s, newQuery]) => {
            mode.value = "Query";
            if (newQuery?.$k && service.readSupported.value) {
                /*queryPromise.value = */
                paging
                    .start(undefined, atob(newQuery.$k))
                    .then(() => {
                        //activeEntity = paging.entities.value[0];
                    });
            } else if (newQuery?.$start !== undefined) {
                const start = newQuery.$start;
                delete newQuery.$start;
                setQuery(newQuery);
                if (start) {
                    paging.start(newQuery);
                } else {
                    paging.reset();
                    if (options.value.autoQuery) {
                        setTimeout(() => submitButton.value?.click());
                    }
                }
            } else {
                setQuery(newQuery);
                paging.reset();
                if (options.value.autoQuery) {
                    setTimeout(() => submitButton.value?.click());
                }
            }

        }, {immediate: true})


        const startLoc = ref(START_COMMAND);
        watch(paging.started, started => {
            if (started) {
                setTimeout(() => {
                    startLoc.value = REFRESH_COMMAND;
                }, 600);
            } else {
                startLoc.value = START_COMMAND;
            }
        }, {immediate: true});

        const scrollToTop = () => window.scrollTo({top: 0, behavior: "smooth"});

        const {locale} = useLocale();

        const queryContext = reactive({
            System: {
                Locale: locale,
            },
            User: {
                Permissions: ["Read"]
            },
            Lookups: lookups,
            State: queryState,
            Query: query,
            QueryModel: queryModel,
            QueryFormModel: queryFormModel,
            //QueryResult: paging.entities,
            //QueryContext: {
            //    Fetching: paging.fetching,
            //    Completed: paging.completed
            //}
        });

        const isActive = (entityContext: Entities.EntityContext) => _activeEntityContext.value === entityContext;
        const isSelected = (entity: any) => entity === _activeEntityContext.value?.Entity;

        const createAsync = async () => {
            let newEntity = service.emptySupported
                ? await service.empty(query.value)
                : undefined;
            if (!newEntity) {
                newEntity = createEntity(props.entityModel);
            }
            newEntity.$$ = {
                forceEdit: true,
                entityModel: _propsEntityModel.value,
                formModel: _propsFormModel.value,
                created: Date.now()
            }
            newEntities.value.splice(0, 0, newEntity);
            //newEntities.value.push(newEntity);
        };

        const onQuery = (e: PromisableEvent) => {
            const newQuery: any = {};
            _query.value && Object.entries(_query.value).forEach(([prop, value]) => {
                if (!isUndefinedOrNull(value) && value !== "") {
                    newQuery[prop] = toRaw(value);
                }
            });
            const promise = new Promise(resolve =>
                router
                    .push({query: newQuery})
                    .then(error => {
                        resolve(error
                            ? paging.start(newQuery)
                            : paging.promise.value);
                    }));
            e?.setPromise(promise, {minDuration: false})
        }

        const onResetQuery = () => {
            paging.reset();
            router.push({query: {}});
        };

        const onRowClick = (entityContext: Entities.EntityContext) => {
            if (isActive(entityContext)) {
                if (dataGridInline.value && dataGridInline.value.onClose) {
                    dataGridInline.value.onClose();
                } else {
                    _activeEntityContext.value = undefined;
                }
            } else {
                _activeEntityContext.value = entityContext;
            }
            //emit("update:activeEntity", record);
        };

        const onRowDblClick = (entity?: any) => {
            if (dataGridInline.value && dataGridInline.value.onEditInline) {
                dataGridInline.value.onEditInline();
            }
        };

        const onCreate = (e: PromisableEvent) =>
            e.setPromise(createAsync(), {minDuration: false, successDuration: false});

        const onUpdate = (entity: any, entityContext: Entities.EntityContext, index: number) => {
            entityContext.Entity = entity;
            paging.set(entity, index)
            emit("update", entity);
        };

        const onUpdateNew = (entity: any) => {
            paging.insert(entity);
            onCloseNew(entity);
        }

        const onClose = (entity: any, entityContext: Entities.EntityContext) => {
            if (isActive(entityContext)) {
                _activeEntityContext.value = undefined;
            }
            emit("close", entity);
        };

        const onCloseNew = (entity: any) => {
            const index = newEntities.value.indexOf(entity);
            newEntities.value.splice(index, 1);
        }

        const onRemove = (entityIndex: number) => {
            paging.remove(entityIndex);
            if (paging.hasMore.value) {
                paging.more(1);
            }
        }

        const onImport = () => {
            paging.reset();
            mode.value = "Import";
        }

        const onCancelImport = () => {
            mode.value = "Query";
        }

        const insert = (entity: any) => {
            return newEntities.value.splice(0, 0, {
                ...entity,
                $$: {
                    forceEdit: true,
                    entityModel: entityModel.value,
                    formModel: formModel.value,
                    created: Date.now()
                } as Partial<Entities.DollarDollar>
            });
        };

        const onDuplicate = (entity: any) =>
            insert(entity);

        const {read: readEntity, ensureClipboardJson} = useClipboardData();

        const paste = async () => {
            let entity = await readEntity(options.value?.clipboardType);
            
            const newEntity = service.emptySupported.value
                ? await service.empty(_query.value)
                : undefined;
            entity = options.value.fromPortable(entity, newEntity);

            if (service.adjustSupported.value) {
                entity = await service.adjust(entity);
                console.log("adjust", entity);
            }

            insert(entity);
        }

        const onPaste = (e: PromisableEvent) =>
            e.setPromise(paste(), {failedToast: true});

        const onKeyboardPaste = (event: ClipboardEvent) => {
            if ((event.target as HTMLElement)?.tagName === "DIV") {
                const text = event.clipboardData.getData("text")
                try {
                    const entity = ensureClipboardJson(text);
                    onDuplicate(entity);
                } catch (e) {
                    addWarningToast(e);
                }
            }
        }

        onMounted(() => {
            useEventListener(document, "paste", onKeyboardPaste);
        });

        return {
            dataGridInline,
            submitButton,
            t,
            options,
            queryContext,
            query,
            newEntities,
            ...service,
            ...paging,
            testMode,
            colspan,

            entityModel,
            formModel,

            showQueryForm,
            showImport,
            showResult,

            infiniteElement,
            startLoc,

            isSelected,

            endVisible,
            scrollToTop,

            onQuery,
            onCreate,
            onRowClick,
            onRowDblClick,
            onResetQuery,
            onRemove,
            onImport,
            onCancelImport,
            onCloseNew,
            onDuplicate,
            onPaste,
            onUpdate,
            onUpdateNew,
            onClose
        }

    }
});

</script>